import useAnchorBaseQuery from "@hooks/queries/useAnchorBaseQuery";

import BikeAllStatisticsTable from "./BikeAllStatisticsTable";
import BikeAreaStatisticsTable from "./BikeAreaStatisticsTable";

function BikeStatisticsTable() {
  const { areas } = useAnchorBaseQuery();

  return (
    <>
      <BikeAllStatisticsTable />
      {areas?.map((area) => (
        <BikeAreaStatisticsTable
          name={area.name}
          code={area.code}
          key={area.code}
        />
      ))}
    </>
  );
}

export default BikeStatisticsTable;
