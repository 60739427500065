import { getFetcher } from "@network/fetchers";
import { Statistics } from "types/statistics.types";

// 서비스 전체 통계 가져오기
function getAll() {
  return getFetcher<Statistics>("/v1/statistics/service");
}

// area code로 가져오가
function get(code?: string) {
  return getFetcher<Statistics>("/v1/statistics/area", { code });
}

const statisticsApi = () => {
  return {
    getAll,
    get
  };
};

export default statisticsApi;
