import { useState, useCallback } from "react";

import bikeApi from "@network/bike/bikeApi";
import { Bike } from "types/bike.types";

const useBikeLiseBaseQuery = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<unknown | null>(null);
  const [bikes, setBikes] = useState<Bike[]>([]);

  const getBikes = async () => {
    try {
      setError(null);
      setIsLoading(true);
      const bikes = await bikeApi().getAll();
      setBikes(bikes);
    } catch (e: unknown) {
      if (e instanceof Error) setError(e);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    bikes,
    setBikes,
    getBikes: useCallback(getBikes, []),
    isLoading,
    error
  };
};

export default useBikeLiseBaseQuery;
