import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Area } from "types/area.types";
import { Bike } from "types/bike.types";

import BikeStatisticsTable from "../BikeStatistics/BikeStatisticsTable";

type Props = {
  areas: Area[];
  bikes: Bike[];
  isLoading: boolean;
};

function BikeStatistics({ isLoading }: Props) {
  return (
    <>
      <Typography variant="h5" component="h3" sx={{ mb: 3 }}>
        기기 현황
      </Typography>
      <StyledTableConatinerBox>
        {isLoading ? (
          <StyledLoadingBox>
            <CircularProgress />
          </StyledLoadingBox>
        ) : (
          <BikeStatisticsTable />
        )}
      </StyledTableConatinerBox>
    </>
  );
}

const StyledTableConatinerBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1)
}));

const StyledLoadingBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center"
}));

export default BikeStatistics;
