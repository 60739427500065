import useServiceAreaStatisticsBaseQuery from "@hooks/queries/useServiceAreaStatisticsBaseQuery";
import { StatisticsTableGroup } from "shared-ui";

function BikeAllStatisticsTable({
  code,
  name
}: {
  code: string;
  name: string;
}) {
  const { serviceStatistics } = useServiceAreaStatisticsBaseQuery(code);

  return (
    <>
      {serviceStatistics ? (
        <StatisticsTableGroup
          rows={[serviceStatistics]}
          title={name}
        ></StatisticsTableGroup>
      ) : null}
    </>
  );
}

export default BikeAllStatisticsTable;
