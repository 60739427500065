import { MapMarkerIconType } from "types/map.types";

import getBikeIcon from "../../utils/getBikeIcon";

function getMapIcon({
  status,
  vendor,
  isSelected,
  iconStatus
}: {
  status?: string;
  vendor?: number;
  isSelected?: boolean;
  iconStatus: MapMarkerIconType;
}) {
  if (iconStatus === "bike_status" && status && vendor) {
    return {
      url: getBikeIcon(status, vendor, isSelected),
      scaledSize: new naver.maps.Size(20, 20)
    };
  }

  if (iconStatus === "destination_start") {
    return {
      url: `${process.env.NEXT_PUBLIC_CDN_DOMAIN}/assets/etc/destination/marker/riding_start.png`,
      size: new naver.maps.Size(40, 40)
    };
  }

  if (iconStatus === "destination_end") {
    return {
      url: `${process.env.NEXT_PUBLIC_CDN_DOMAIN}/assets/etc/destination/marker/riding_end.png`,
      size: new naver.maps.Size(40, 40)
    };
  }

  if (iconStatus === "dynamo") {
    return {
      url: `${process.env.NEXT_PUBLIC_CDN_DOMAIN}/assets/etc/location/marker/dynamo.png`,
      size: new naver.maps.Size(40, 40),
      anchor: naver.maps.Position.BOTTOM_CENTER
    };
  }
  return { url: "" };
}

export default getMapIcon;
