import React from "react";

import useDialog from "@hooks/useDialog";
import { Bike, BikeIdle } from "types/bike.types";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

import IdleModal from "./IdleModal";

type Props = {
  setIdleFilters: (bikeIdles: BikeIdle[] | Bike[]) => void;
  handleRefresh: () => void;
};

function NaverMapIdleFilter({ setIdleFilters, handleRefresh }: Props) {
  const { open, setOpen } = useDialog();

  const handleModalClick = () => {
    setOpen(!open);
  };
  return (
    <div>
      <StyledFilterButton
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleModalClick}
        variant="outlined"
        color="secondary"
        size="small"
      >
        <HourglassEmptyIcon />
      </StyledFilterButton>
      <IdleModal
        open={open}
        setOpen={setOpen}
        setIdleFilters={setIdleFilters}
        handleRefresh={handleRefresh}
      />
    </div>
  );
}

const StyledFilterButton = styled(Button)(({ theme }) => ({
  position: "absolute",
  top: "60px",
  left: "10px",
  minWidth: "45px",
  minHeight: "45px",
  padding: 0,
  borderRadius: "50%",
  zIndex: 1,
  background: theme.palette.background.paper + "!important"
}));

export default NaverMapIdleFilter;
