import { getFetcher } from "@network/fetchers";
import { GetBikeIdleResponse } from "types/bike.types";

function get(time?: string) {
  return getFetcher<GetBikeIdleResponse>("bike/idle", { n: time });
}

const bikeIdleApi = () => {
  return {
    get
  };
};

export default bikeIdleApi;
