import { useState, useCallback } from "react";

import bikeIdleApi from "@network/bikeIdle/bikeIdleApi";
import { BikeIdle } from "types/bike.types";

const useBikeIdlesBaseQuery = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<unknown>(null);
  const [bikeIdles, setBikeIdles] = useState<BikeIdle[]>([]);

  const getBikeIdles = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const responseBikes = await bikeIdleApi().get();
      setBikeIdles(responseBikes);
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    bikeIdles,
    getBikeIdles: useCallback(getBikeIdles, []),
    isLoading,
    error
  };
};

export default useBikeIdlesBaseQuery;
