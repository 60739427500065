import NaverMap from "@services/naverMap";
import { Clickable } from "types/map.types";
import { MapMarkerIconType } from "types/map.types";

type SelectedBike = {
  target: Clickable;
  marker: naver.maps.Marker;
};

type Observer = (observeTarget?: any) => void;

class DashboardMap extends NaverMap {
  private observers: Observer[] = [];
  protected selectedMarker?: SelectedBike | null;

  constructor() {
    super();
  }

  public attach(observer: Observer) {
    this.observers.push(observer);
  }

  public detach(observerToRemove: Observer) {
    this.observers = this.observers.filter(
      (observer) => observer !== observerToRemove
    );
  }

  public notify(target?: any) {
    this.observers.forEach((observer) => observer(target));
  }

  public updatePreviousSelectedMarker() {
    if (!this.selectedMarker) return;

    const updatedMarker = this.updateMarkerBySelectStatus(
      this.selectedMarker.marker,
      this.selectedMarker.target,
      false
    );

    const uuid = this.selectedMarker.target.uuid;

    this.markers[uuid] = updatedMarker;

    this.addMarkerClickEvent(updatedMarker, this.selectedMarker.target);
    this.selectedMarker = null;
  }

  public updateNewSelectedMarker(target: Clickable) {
    const uuid = target.uuid;

    const newSelectedMarker = this.updateMarkerBySelectStatus(
      this.markers[uuid],
      target,
      true
    );
    this.selectedMarker?.marker.setMap(null);
    this.markers[uuid]?.setMap(null);

    this.selectedMarker = { target: target, marker: newSelectedMarker };
    this.markers[uuid] = newSelectedMarker;

    this.addMarkerClickEvent(newSelectedMarker, target);

    this.map &&
      this.map.setCenter(
        new naver.maps.LatLng(target.position[1], target.position[0])
      );

    if (this.selectedInfo?.uuid !== uuid) {
      this.selectedInfo?.target.close();
      this.handleToggleWindowInfo(uuid);
    }
  }

  public addMarkerClickEvent(newMarker: naver.maps.Marker, target: Clickable) {
    naver.maps.Event.addListener(newMarker, "click", () => {
      this.handleToggleWindowInfo(target.uuid);
      this.notify(target);
    });
  }

  public removeAllMarkers() {
    this.updatePreviousSelectedMarker();
    Object.values(this.markers)?.forEach((marker) => marker.setMap(null));
    this.markers = {};
  }

  public updateMarkerBySelectStatus(
    marker: naver.maps.Marker,
    target: Clickable,
    isSelected: boolean
  ) {
    marker && marker.setMap(null);
    return this.drawMarker({
      position: target.position,
      status: target.status,
      vendor: target.vendor,
      isSelected: isSelected
    });
  }
  ㅅ;

  public setClikableMarkers(
    targets: Clickable[],
    iconStatus?: MapMarkerIconType
  ) {
    targets?.forEach((target) => {
      const newMarker = this.setMarker({
        uuid: target.uuid,
        position: target.position,
        status: target.status,
        vendor: target.vendor,
        iconStatus
      });

      this.infos &&
        (this.infos[target.uuid] = this.setWindowInfoMarker(target.info));

      this.addMarkerClickEvent(newMarker, target);
    });
  }

  public clearAndSetClikableMarkers(
    targets: Clickable[],
    iconStatus?: MapMarkerIconType
  ) {
    this.removeAllMarkers();
    this.removeAllWindowInfos();

    this.setClikableMarkers(targets, iconStatus);
  }
}

export default DashboardMap;
