import { Position, Geoblock } from "types/geo.types";
import { MapMarkerIconType } from "types/map.types";
import { MapOption, PolygonOption, PolylineOption } from "types/map.types";

import getMapMarkerOption from "./getMapMarkerOption";

class NaverMap {
  protected mapOption = {
    useStyleMap: true,
    zoom: 14,
    gridSize: 50
  };
  protected polygonOption = {
    fillColor: "#ff2100",
    fillOpacity: 0.3,
    strokeColor: "#ff2100",
    strokeOpacity: 0.6,
    strokeWeight: 1
  };
  protected polylineOption = {
    fillColor: "#007EEA",
    fillOpacity: 0.3,
    strokeColor: "#007EEA",
    strokeOpacity: 0.6,
    strokeWeight: 3
  };

  public map?: naver.maps.Map;
  protected markers: Record<string, naver.maps.Marker>;
  protected polygon?: naver.maps.Polygon;
  protected polylines: naver.maps.Polyline[];
  protected polyBlocks: naver.maps.Polygon[];
  protected infos: Record<string, naver.maps.InfoWindow>;
  protected selectedInfo?: {
    target: naver.maps.InfoWindow;
    uuid: string;
  } | null;

  constructor() {
    this.markers = {};
    this.polylines = [];
    this.polyBlocks = [];
    this.infos = {};
  }

  public set setMapOption(option: Partial<MapOption>) {
    this.mapOption = { ...this.mapOption, ...option };
  }

  public set setPolygonOption(option: Partial<PolygonOption>) {
    this.polygonOption = { ...this.polygonOption, ...option };
  }

  public set setPolylineOption(option: Partial<PolylineOption>) {
    this.polygonOption = { ...this.polylineOption, ...option };
  }

  public setMap(elem: string | HTMLElement, option = this.mapOption) {
    this.map = new naver.maps.Map(elem, option);
  }

  public drawMarker({
    position,
    status,
    vendor,
    isSelected = false,
    iconStatus = "bike_status"
  }: {
    position: Position;
    status?: string;
    vendor?: number;
    isSelected?: boolean;
    iconStatus?: MapMarkerIconType;
    option?: Partial<naver.maps.ImageIcon>;
  }) {
    const markerOption = getMapMarkerOption({
      status,
      vendor,
      isSelected,
      iconStatus
    });

    return new naver.maps.Marker({
      position: new naver.maps.LatLng(position[1], position[0]),
      map: this.map as naver.maps.Map,
      icon: markerOption
    });
  }

  public setMarker({
    uuid,
    position,
    status,
    vendor,
    iconStatus
  }: {
    uuid: string;
    position: Position;
    status?: string;
    vendor?: number;
    iconStatus?: MapMarkerIconType;
  }) {
    if (this.markers[uuid]) return this.markers[uuid];

    const newMarker = this.drawMarker({
      position: position,
      status: status,
      vendor: vendor,
      iconStatus
    });
    this.markers[uuid]?.setMap(null);
    this.markers[uuid] = newMarker;
    return newMarker;
  }

  public setCenter(position?: Position | null) {
    if (!position) return;
    this.map &&
      this.map.setCenter(new naver.maps.LatLng(position[1], position[0]));
  }

  public setClikableMarkers(
    targets: {
      uuid: string;
      info: string;
      position: Position;
      status?: string;
      vendor?: number;
    }[],
    iconStatus?: MapMarkerIconType
  ) {
    return targets?.forEach((target) => {
      const newMarker = this.setMarker({
        uuid: target.uuid,
        position: target.position,
        status: target.status,
        vendor: target.vendor,
        iconStatus
      });

      this.infos &&
        (this.infos[target.uuid] = this.setWindowInfoMarker(target.info));
      this.addMarkerClickEvent(newMarker, target);
    });
  }

  public addMarkerClickEvent(
    newMarker: naver.maps.Marker,
    target: {
      uuid: string;
      info: string;
      position: Position;
      status?: string;
      vendor?: number;
    }
  ) {
    naver.maps.Event.addListener(newMarker, "click", () => {
      this.handleToggleWindowInfo(target.uuid);
    });
  }

  public removeAllMarkers() {
    Object.values(this.markers)?.forEach((marker) => marker.setMap(null));
    this.markers = {};
  }

  public drawPolygon(lines: Position[], option = this.polygonOption) {
    return new naver.maps.Polygon({
      map: this.map,
      paths: lines.map((line) => new naver.maps.LatLng(line[1], line[0])),
      ...option
    });
  }

  public setGeoBlocks(geoblocks: Geoblock[]) {
    const newPolys = geoblocks.flatMap((block) => {
      const isActive = block.is_active;
      const polygonOption = {
        ...this.polygonOption,
        fillColor: isActive ? "#ff2100" : "#000000",
        strokeColor: isActive ? "#ff2100" : "#000000"
      };
      return block.polygon.coordinates.map((lines: Position[]) =>
        this.drawPolygon(lines, polygonOption)
      );
    });
    this.polyBlocks = [...this.polyBlocks, ...newPolys];
  }

  public drawPolyline(line: Position) {
    return new naver.maps.LatLng(line[1], line[0]);
  }

  public setPolylines(lineList: Position[], option = this.polylineOption) {
    this.polylines?.push(
      new naver.maps.Polyline({
        map: this.map,
        path: lineList.map((line) => this.drawPolyline(line)),
        ...option
      })
    );
  }

  public removeAllPolylines() {
    this.polylines?.forEach((line) => line.setMap(null));
    this.polylines = [];
  }

  public clearAllMapItems() {
    this.removeAllWindowInfos();
    this.polyBlocks.forEach((block) => block.setMap(null));
    this.polylines.forEach((poly) => poly.setMap(null));
    for (const [_, marker] of Object.entries(this.markers)) {
      marker.setMap(null);
    }
    this.polyBlocks = [];
    this.polylines = [];
    this.markers = {};
  }

  public clearAll() {
    this.clearAllMapItems();
    this.map?.destroy();
    this.map = undefined;
  }

  public setWindowInfoMarker(info: string) {
    const infoWindow = new naver.maps.InfoWindow({
      content: `<div style="width:100px;text-align:center;"><b>${info}</b></div>`
    });
    return infoWindow;
  }

  public removeInfoMaker(infoWindow: any) {
    infoWindow.setMap(null);
  }

  public handleToggleWindowInfo(uuid: string) {
    if (!this.map || !this.infos) return;

    const marker = this.markers[uuid];
    const infoWindow = this.infos[uuid];

    if (!infoWindow) return;

    if (infoWindow.getMap()) {
      infoWindow.close();
    } else {
      infoWindow.open(this.map, marker);
      this.map.panTo(marker.getPosition(), {});
      this.selectedInfo = { uuid, target: infoWindow };
    }
  }

  public removeAllWindowInfos() {
    Object.values(this.infos)?.forEach((info) => this.removeInfoMaker(info));
    this.infos = {};
    this.selectedInfo = null;
  }
}

export default NaverMap;
