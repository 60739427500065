import { useEffect, useRef, useState, useMemo, memo } from "react";

import MaintenanceControlBoard from "@components/MaintenanceControlBoard";
import MaintenanceSNAutoComplete from "@components/MaintenanceSNAutoComplete";
import NaverMapIdleFilter from "@components/NaverMapIdleFilter";
import NaverMapStatusFilter from "@components/NaverMapStatusFilter";
import DashboardMapService from "@features/dashboard/services/dashboardMapService";
import useGetBikeDetail from "@hooks/queries/useBikeDetailBaseQuery";
import useIsMount from "@hooks/useIsMount";
import { Bike, BikeDetail, BikeIdle } from "types/bike.types";
import { Geoblock } from "types/geo.types";
import RefreshIcon from "@mui/icons-material/Refresh";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import DashboardMap from "@services/dashboardMap";
import { anchorSelector } from "@store/recoil/anchorRecoil";
import { useRouter } from "next/router";
import { useRecoilValue } from "recoil";

type Props = {
  bikes: Bike[];
  geoblocks: Geoblock[];
  bikeIdles?: BikeIdle[];
  isLoading: boolean;
  setBikes: (bike: Bike[]) => void;
  getBikes: () => void;
  getGeoblocks: () => void;
};

function NaverMap({
  bikes,
  geoblocks,
  bikeIdles,
  isLoading,
  setBikes,
  getBikes,
  getGeoblocks
}: Props) {
  const mapRef = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const { areas } = useRecoilValue(anchorSelector);
  const [statusFilters, setStatusFilters] = useState<string[]>([]);
  const [idleFilter, setIdleFilters] = useState<BikeIdle[] | Bike[]>([]);
  const [selectedBike, setSelectedBike] = useState<Bike | null>();
  const [selectedBikeDetail, setSelectedBikeDetail] =
    useState<BikeDetail | null>();
  const { getBikeDetail } = useGetBikeDetail();

  const isMount = useIsMount();
  const isInitializedMap = useRef(false);
  const dashboardMapService = useMemo(
    () => new DashboardMapService(new DashboardMap()),
    []
  );

  useEffect(() => {
    dashboardMapService.setInitialMap(mapRef, areas[0]?.centroid);
    dashboardMapService.attach(setSelectedBike);
    return () => {
      dashboardMapService.detach(setSelectedBike);
      dashboardMapService.clearAll();
    };
  }, []);

  useEffect(() => {
    if (isInitializedMap.current || !bikes.length) return;
    dashboardMapService.setBikeMarkers(bikes);
    isInitializedMap.current = true;
  }, [bikes]);

  useEffect(() => {
    dashboardMapService.setGeoblocks(geoblocks);
  }, [geoblocks]);

  useEffect(() => {
    if (isMount) return;
    if (statusFilters.length) {
      dashboardMapService.setBikesByStatusFilter(statusFilters, bikes);
    } else {
      dashboardMapService.resetBikesByStatusFilter(bikes);
    }
  }, [statusFilters]);

  useEffect(() => {
    if (isMount) return;
    dashboardMapService.setBikesByIdleFilter(idleFilter, bikes);
  }, [idleFilter]);

  useEffect(() => {
    if (!selectedBike) return;
    const updateBikesBySelectedUpdate = (selected: Bike) => {
      const newBikes = bikes.map((bike) => {
        if (bike.sn === selected.sn) return selected;
        else return bike;
      });
      setBikes(newBikes);
    };

    const updateBikeDetail = async (id: number) => {
      // 두값이 같은경우, 중복실행이므로 Early Return
      if (selectedBikeDetail === selectedBike) {
        return;
      }
      const bikeDetail = await getBikeDetail(id);
      setSelectedBikeDetail(bikeDetail);
    };

    updateBikesBySelectedUpdate(selectedBike);
    dashboardMapService.updateSelectedBike(selectedBike);
    updateBikeDetail(selectedBike.id);
  }, [selectedBike]);

  useEffect(() => {
    const updateSelectedByDetailUpdated = () => {
      if (selectedBike !== selectedBikeDetail) {
        setSelectedBike(selectedBikeDetail);
      }
    };
    updateSelectedByDetailUpdated();
  }, [selectedBikeDetail]);

  useEffect(() => {
    const changeSelectedBikeFromQuery = () => {
      if (isInitializedMap.current && router.query.sn) {
        const selectedFromRoute = bikes.filter(
          (bike) => bike.sn === router.query.sn
        )[0];

        if (selectedFromRoute) {
          setSelectedBike(selectedFromRoute);
        }
      }
    };
    changeSelectedBikeFromQuery();
  }, [router.query, isInitializedMap.current]);

  const handleRefresh = () => {
    router.replace(router.basePath);
    isInitializedMap.current = false;
    dashboardMapService.clearAllMapItems();
    setSelectedBike(null);
    setSelectedBikeDetail(null);
    setStatusFilters([]);
    getBikes();
    getGeoblocks();
  };

  return (
    <Box sx={{ position: "relative" }}>
      {isLoading && (
        <StyledMapLoadingContainer>
          <CircularProgress />
        </StyledMapLoadingContainer>
      )}
      <StyledFilterContainer>
        <NaverMapStatusFilter
          statusFilters={statusFilters}
          setStatusFilters={setStatusFilters}
        />
        <NaverMapIdleFilter
          setIdleFilters={setIdleFilters}
          handleRefresh={handleRefresh}
        />
        <Box
          ref={mapRef}
          style={{ width: "100%", height: "700px", maxHeight: "60vh" }}
        ></Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <MaintenanceSNAutoComplete
              bikes={bikes}
              selectedBike={selectedBike}
              setSelectedBike={setSelectedBike}
            />
            <IconButton
              aria-label="새로고침"
              onClick={handleRefresh}
              sx={{ mt: 2 }}
            >
              <RefreshIcon />
            </IconButton>
          </Box>
          {selectedBikeDetail && (
            <MaintenanceControlBoard
              bike={selectedBikeDetail}
              bikeIdles={bikeIdles}
              setSelectedBike={setSelectedBikeDetail}
            />
          )}
        </Box>
      </StyledFilterContainer>
    </Box>
  );
}

const StyledMapLoadingContainer = styled(Box)(() => ({
  position: "absolute",
  width: "100%",
  height: "700px",
  maxHeight: "60vh",
  top: "16px",
  left: "16px",
  zIndex: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}));

const StyledFilterContainer = styled(Box)(() => ({
  position: "relative"
}));

export default memo(NaverMap);
