import { RefObject } from "react";

import DashboardMap from "@services/dashboardMap";
import { Bike, BikeIdle } from "types/bike.types";
import Position, { Geoblock } from "types/geo.types";

type SelectedBikeObserver = (bike: Bike | null) => void;

class DashboardMapService {
  public dashboardMap?: DashboardMap;

  constructor(dashboardMap: DashboardMap) {
    this.dashboardMap = dashboardMap;
  }

  public setInitialMap(mapRef: RefObject<HTMLDivElement>, center: Position) {
    mapRef.current && this.dashboardMap?.setMap(mapRef.current);
    this.dashboardMap?.setCenter(center);
  }

  public attach(observers: SelectedBikeObserver) {
    this.dashboardMap?.attach(observers);
  }

  public detach(observerToRemove: SelectedBikeObserver) {
    this.dashboardMap?.detach(observerToRemove);
  }

  private transformClickableBikes(bikes: Bike[]) {
    return bikes.map((bike) => {
      return {
        ...bike,
        uuid: bike.sn,
        info: bike.sn,
        position: bike.location,
        status: bike.status,
        vendor: bike.vendor
      };
    });
  }

  public setBikeMarkers(bikes: Bike[]) {
    const clickableBikes = this.transformClickableBikes(bikes);
    bikes && this.dashboardMap?.clearAndSetClikableMarkers(clickableBikes);
  }

  public setGeoblocks(geoblocks: Geoblock[]) {
    geoblocks.length && this.dashboardMap?.setGeoBlocks(geoblocks);
  }

  private transformClickableMarker(bike: Bike) {
    return {
      ...bike,
      uuid: bike.sn,
      info: bike.sn,
      position: bike.location,
      status: bike.status,
      vendor: bike.vendor
    };
  }

  private transformStatusFilter(statusFilters: string[], bikes: Bike[]) {
    const transformFilters = statusFilters.reduce((arr: string[], filter) => {
      if (filter === "LOW") arr.push("LAV", "LNB", "LNP", "LB", "LP", "LRD");
      else arr.push(filter);
      return arr;
    }, []);

    const filtered = this.transformClickableBikes(bikes).filter((bike: Bike) =>
      transformFilters.includes(bike.status)
    );

    return filtered;
  }

  public setBikesByStatusFilter = (statusFilters: string[], bikes: Bike[]) => {
    const filtered = this.transformStatusFilter(statusFilters, bikes);
    this.dashboardMap?.clearAndSetClikableMarkers(filtered);
  };

  public resetBikesByStatusFilter(bikes: Bike[]) {
    const clickableBikes = this.transformClickableBikes(bikes);
    this.dashboardMap?.clearAndSetClikableMarkers(clickableBikes);
  }

  public setBikesByIdleFilter(
    idlefilter: BikeIdle[] | Bike[],
    originalBikes: Bike[]
  ) {
    const clickableBikes = this.transformClickableBikes(originalBikes);
    const filteredIdlesFromOriginal = clickableBikes.filter((bike: Bike) =>
      idlefilter
        .map((idleBike) => {
          return idleBike.id;
        })
        .includes(bike.id)
    );

    this.dashboardMap?.clearAndSetClikableMarkers(filteredIdlesFromOriginal);
  }

  public updateSelectedBike(bikeDetail: Bike) {
    this.dashboardMap?.updatePreviousSelectedMarker();
    this.dashboardMap?.updateNewSelectedMarker(
      this.transformClickableMarker(bikeDetail)
    );
  }
  public clearAll() {
    this.dashboardMap?.clearAllMapItems();
    this.dashboardMap = undefined;
  }

  public clearAllMapItems() {
    this.dashboardMap?.clearAllMapItems();
  }
}
export default DashboardMapService;
