import { useEffect } from "react";

import useBikeIdleFiltersBaseQuery from "@hooks/queries/useBikeIdleFiltersBaseQuery";
import useAlert from "@hooks/useAlert";
import { Bike, BikeIdle } from "types/bike.types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";

type Props = {
  open: boolean;
  setOpen: (val: boolean) => void;
  setIdleFilters: (bikeIdles: BikeIdle[] | Bike[]) => void;
  handleRefresh: () => void;
};

function IdleModal({ open, setOpen, setIdleFilters, handleRefresh }: Props) {
  const { getBikeIdleFilters } = useBikeIdleFiltersBaseQuery();
  const { handleSubmit, control, setValue } = useForm({
    defaultValues: { idle: "" }
  });
  const { showAlert } = useAlert();

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmitIdleFilter = async (data: { idle: string }) => {
    if (!Number.isInteger(parseInt(data.idle))) {
      showAlert({ content: "숫자 이외에 입력이 불가합니다" });
      return;
    }

    const idleInHourString = (parseInt(data.idle) * 60).toString();

    if (idleInHourString === "0") {
      handleRefresh();
      return;
    }

    const idleBikesReponse = await getBikeIdleFilters(idleInHourString);
    setIdleFilters(idleBikesReponse ?? []);
    handleClose();
  };

  useEffect(() => {
    setValue("idle", "");
  }, [open]);

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <form
          onSubmit={handleSubmit((data) => onSubmitIdleFilter(data))}
          className="form"
        >
          <DialogContent>
            <DialogContentText>
              현재 입력한 시간(단위:시간)이상 대기중인 자전거를
              보여줍니다.(0입력시 전체)
            </DialogContentText>

            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  size="small"
                  sx={{ pt: "8px" }}
                  type="number"
                />
              )}
              name="idle"
              control={control}
            />
          </DialogContent>
          <DialogActions>
            <Button type="submit">조회</Button>
            <Button onClick={handleClose}>취소</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default IdleModal;
