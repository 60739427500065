import geoblockApi from "@network/geoblock/geoblockApi";
import { geoblockQueryKeys } from "@store/react-query/geoblockQueryKeys";
import { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "hooks/useQuery";
import { Geoblock } from "types/geo.types";

const useGeoblockListBaseQuery = (options?: UseQueryOptions<Geoblock[]>) => {
  const { data, error, isFetching, refetch } = useQuery(
    geoblockQueryKeys.geoblockAll,
    () => geoblockApi().getAll(),
    options
  );

  return {
    geoblockList: data ?? [],
    geoblockListLoading: isFetching,
    geoblockListError: error,
    refetchgeoblockList: refetch
  };
};

export default useGeoblockListBaseQuery;
