import { useState, useCallback } from "react";

import bikeApi from "@network/bike/bikeApi";
import bikeIdleApi from "@network/bikeIdle/bikeIdleApi";

const useBikeIdleFiltersBaseQuery = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<unknown>(null);

  const getBikeIdleFilters = async (time: string) => {
    try {
      setIsLoading(true);
      let responseBikes = [];
      if (time === "0") responseBikes = await bikeApi().getAll();
      else responseBikes = await bikeIdleApi().get(time);
      return responseBikes;
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    getBikeIdleFilters: useCallback(getBikeIdleFilters, []),
    isLoading,
    error
  };
};

export default useBikeIdleFiltersBaseQuery;
