import {
  useQuery as useQueryOrigin,
  UseQueryOptions,
  UseQueryResult
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import useErrorHandler from "./useErrorHandler";

export declare type QueryKey = readonly unknown[];

export function useQuery<TResult>(
  queryKey: QueryKey,
  queryFn: (parmas?: any) => Promise<TResult>,
  options: UseQueryOptions<TResult, any> = {}
): UseQueryResult<TResult, AxiosError> {
  const { onError } = options;
  const { errorHandler } = useErrorHandler();

  return useQueryOrigin<TResult, AxiosError>(queryKey, queryFn, {
    useErrorBoundary: !onError,
    ...options,
    onError: (err) => errorHandler(err, onError)
  });
}
