import React, { useRef, useState, useEffect } from "react";

import bike from "@constants/bike";
import FilterListIcon from "@mui/icons-material/FilterList";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grow from "@mui/material/Grow";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { styled } from "@mui/material/styles";
import { useForm, Controller } from "react-hook-form";

type Props = {
  statusFilters: string[];
  setStatusFilters: (filters: string[]) => void;
};

function NaverMapStatusFilter({ statusFilters, setStatusFilters }: Props) {
  const { control, handleSubmit, setValue, getValues } = useForm({
    defaultValues: { status: [...statusFilters] }
  });

  const [open, setOpen] = useState(false);

  const anchorRef = useRef<HTMLButtonElement>(null);
  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  function handleSelect(
    _: React.ChangeEvent<HTMLInputElement>,
    checkedStatus: string[]
  ) {
    const isIncludes = statusFilters.some((r) => checkedStatus.indexOf(r) >= 0);
    if (isIncludes) {
      const newStatus = statusFilters.filter(
        (r) => !(checkedStatus.indexOf(r) >= 0)
      );
      setStatusFilters(newStatus);
    } else {
      const newStatus = [...statusFilters, ...checkedStatus];
      setStatusFilters(newStatus);
    }
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  function handleReset() {
    setStatusFilters([]);
  }

  useEffect(() => {
    setValue("status", []);
  }, [statusFilters]);

  return (
    <div>
      <StyledFilterButton
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        variant="outlined"
        color="secondary"
        size="small"
      >
        <FilterListIcon />
      </StyledFilterButton>
      <StyledPopper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="right-start"
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  <form>
                    {bike.mapFilterStatusList.map((status) => (
                      <StyledStatusMenuItem dense key={status.text}>
                        <FormControlLabel
                          control={
                            <Controller
                              name="status"
                              control={control}
                              rules={{ required: true }}
                              render={({ field }) => (
                                <Checkbox
                                  {...field}
                                  size="small"
                                  checked={statusFilters.some(
                                    (r) => status.value.indexOf(r) >= 0
                                  )}
                                  onChange={(e) =>
                                    handleSelect(e, status.value)
                                  }
                                />
                              )}
                            />
                          }
                          key={status.text}
                          label={status.text}
                          sx={{
                            padding: "2px 11px",
                            width: "calc(100% + 11px);"
                          }}
                        />
                      </StyledStatusMenuItem>
                    ))}
                    <StyledResetButton color="error" onClick={handleReset}>
                      초기화
                    </StyledResetButton>
                  </form>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </StyledPopper>
    </div>
  );
}

const StyledFilterButton = styled(Button)(({ theme }) => ({
  position: "absolute",
  top: "10px",
  left: "10px",
  minWidth: "45px",
  minHeight: "45px",
  padding: 0,
  borderRadius: "50%",
  zIndex: 1,
  background: theme.palette.background.paper + "!important"
}));

const StyledStatusMenuItem = styled(MenuItem)(() => ({
  display: "block",
  padding: 0
}));

const StyledResetButton = styled(Button)(() => ({
  display: "flex",
  margin: "0 auto",
  padding: "4px"
}));

const StyledPopper = styled(Popper)(() => ({
  zIndex: 1,
  inset: "0px auto auto 6px !important"
}));

export default NaverMapStatusFilter;
