import Dashboard from "@features/dashboard/components/Dashboard";
import { getLayout } from "@layouts/DashboardLayout";
import { NextPageWithLayout } from "types/layout.types";

const DashboardPage: NextPageWithLayout = () => {
  return <Dashboard />;
};

DashboardPage.getLayout = getLayout;

export default DashboardPage;
