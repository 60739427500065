import { useAlertSnackbar } from "@layouts/hooks/useAlertSnackbar";

export default function useErrorHandler() {
  const { openAlertSnackbar } = useAlertSnackbar();

  const errorHandler = (err, callback) => {
    alertErrorMessage(err);
    callback && callback(err);
    return;
  };

  const alertErrorMessage = (err) => {
    const res = err.response;
    if (!res?.data) return;

    let message = `[${res.status}] ${res.data?.message}`;

    if (res.data.alert) {
      alert(res.data.alert);
    } else if (res.data.detail) {
      message = `[${res.status}]${res.data.detail}`;
    } else if (res.data.code) {
      message = `[${res.status}]${res.data.message}(${res.data.code})`;
    }

    openAlertSnackbar(message);
    return Promise.reject(err);
  };

  return {
    errorHandler,
    alertErrorMessage
  };
}
