import statisticsApi from "@network/statistics/statisticsApi";
import { statisticsQueryKeys } from "@store/react-query/statisticsQueryKeys";
import { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "hooks/useQuery";
import { Statistics } from "types/statistics.types";

const useServiceAreaStatisticsBaseQuery = (
  code?: string,
  options?: UseQueryOptions<Statistics>
) => {
  const {
    data: serviceStatistics,
    isFetching: serviceStatisticsLoading,
    refetch: refetchServiceStatistics,
    error: serviceStatisticsError
  } = useQuery<Statistics>(
    statisticsQueryKeys.statisticsArea(code),
    () => statisticsApi().get(code),
    options
  );
  return {
    serviceStatistics: serviceStatistics,
    serviceStatisticsLoading,
    serviceStatisticsError,
    refetchServiceStatistics
  };
};

export default useServiceAreaStatisticsBaseQuery;
