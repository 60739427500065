import { useEffect, useState, SyntheticEvent } from "react";

import { Bike } from "types/bike.types";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

type Props = {
  bikes: Bike[];
  selectedBike?: Bike | null;
  setSelectedBike: (bike: Bike | null) => void;
};

function MaintenanceSNAutoComplete({
  bikes,
  selectedBike,
  setSelectedBike
}: Props) {
  const [fieldValue, setFieldValue] = useState<Bike | null>(null);

  const handleSNSelect = (_: SyntheticEvent, value: Bike | null) => {
    setFieldValue(value);
    if (value) {
      if (value) setSelectedBike(value);
    }
  };

  const defaultProps = {
    options: bikes.map((bike) => {
      return bike;
    }),
    getOptionLabel: (option: any) => option.sn
  };

  useEffect(() => {
    setFieldValue(selectedBike ?? null);
  }, [selectedBike]);

  return (
    <Box sx={{ mt: 3, flexGrow: 1 }}>
      <Autocomplete
        {...defaultProps}
        disablePortal
        value={fieldValue}
        onChange={(e, value) => {
          handleSNSelect(e, value);
        }}
        isOptionEqualToValue={(option, value) => option.sn === value.sn}
        renderInput={(params) => (
          <TextField
            {...params}
            label="기기번호 검색"
            fullWidth
            sx={{ bgcolor: "background.paper" }}
          />
        )}
      />
    </Box>
  );
}

export default MaintenanceSNAutoComplete;
