import { getFetcher } from "@network/fetchers";
import { GetGeoblockResponse } from "types/geo.types";

function getAll() {
  return getFetcher<GetGeoblockResponse>("/v1/franchise/geoblock");
}

const geoblockApi = () => {
  return {
    getAll
  };
};

export default geoblockApi;
