import useServiceAllStatisticsBaseQuery from "@hooks/queries/useServiceAllStatisticsBaseQuery";
import { StatisticsTableGroup } from "shared-ui";

function BikeAllStatisticsTable() {
  const { allServiceStatistics } = useServiceAllStatisticsBaseQuery();

  return (
    <>
      {allServiceStatistics ? (
        <StatisticsTableGroup
          rows={[allServiceStatistics]}
          title="전체 통계"
        ></StatisticsTableGroup>
      ) : null}
    </>
  );
}

export default BikeAllStatisticsTable;
