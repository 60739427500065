import { useEffect } from "react";

import BikeStatistics from "@features/dashboard/components/BikeStatistics";
import DashboardNaverMap from "@features/dashboard/components/DashboardNaverMap";
import useBikeIdlesBaseQuery from "@hooks/queries/useBikeIdlesBaseQuery";
import useBikeLiseBaseQuery from "@hooks/queries/useBikeLiseBaseQuery";
import useGeoblocksBaseQuery from "@hooks/queries/useGeoblocksBaseQuery";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { anchorSelector } from "@store/recoil/anchorRecoil";
import { useRecoilValue } from "recoil";

const Dashboard = () => {
  const {
    bikes,
    setBikes,
    getBikes,
    isLoading: isBikeLoading
  } = useBikeLiseBaseQuery();
  const { bikeIdles, getBikeIdles } = useBikeIdlesBaseQuery();
  const {
    geoblockList,
    refetchgeoblockList,
    geoblockListLoading: isGeoblockLoading
  } = useGeoblocksBaseQuery();

  const anchor = useRecoilValue(anchorSelector);

  useEffect(() => {
    getBikes();
    refetchgeoblockList();
    getBikeIdles();
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <StyledContainerGrid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <Grid item xs={12} md={8} sx={{ position: "relative" }}>
          <DashboardNaverMap
            bikes={bikes}
            geoblocks={geoblockList ?? []}
            bikeIdles={bikeIdles}
            isLoading={isBikeLoading || isGeoblockLoading}
            setBikes={setBikes}
            getBikes={getBikes}
            getGeoblocks={refetchgeoblockList}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <BikeStatistics
            bikes={bikes ?? []}
            areas={anchor?.areas ?? []}
            isLoading={isBikeLoading}
          />
        </Grid>
      </StyledContainerGrid>
    </Box>
  );
};

const StyledContainerGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    gap: "16px"
  }
}));

export default Dashboard;
