import anchorApi from "@network/anchor/anchorApi";
import { anchorQueryKeys } from "@store/react-query/anchorQueryKeys";
import { useQuery } from "hooks/useQuery";

const useAnchorBaseQuery = () => {
  const { data, error, isFetching } = useQuery(
    anchorQueryKeys.anchorAll,
    anchorApi().get,
    {
      cacheTime: Infinity,
      staleTime: Infinity
    }
  );

  return {
    areas: data?.areas ?? [],
    organization: data?.organizations,
    regions: data?.regions ?? [],
    last_geoblock_change: data?.last_geoblock_change,
    error: error,
    isLoading: isFetching
  };
};

export default useAnchorBaseQuery;
