import statisticsApi from "@network/statistics/statisticsApi";
import { statisticsQueryKeys } from "@store/react-query/statisticsQueryKeys";
import { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "hooks/useQuery";
import { Statistics } from "types/statistics.types";

const useServiceAllStatisticsBaseQuery = (
  options?: UseQueryOptions<Statistics>
) => {
  const {
    data: allServiceStatistics,
    isFetching: allServiceStatisticsLoading,
    refetch: refetchAllServiceStatistics,
    error: allServiceStatisticsError
  } = useQuery<Statistics>(
    statisticsQueryKeys.statisticsServiceAll,
    () => statisticsApi().getAll(),
    options
  );
  return {
    allServiceStatistics: allServiceStatistics,
    allServiceStatisticsLoading,
    allServiceStatisticsError,
    refetchAllServiceStatistics
  };
};

export default useServiceAllStatisticsBaseQuery;
